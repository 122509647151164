import React from "react"
import { graphql, Link } from "gatsby"
import BaseLayout from "../templates/baseLayout"
import { PageInformation } from "trv-ebus-tcom-reactcomponents"
import "../styles/main.scss"
import getSitewideMessages from "../utilities/getSiteWideMessages"
import { object, shape } from "prop-types"

export const query = graphql`
  query {
    ecsSitewideMessages {
      content {
        messageType
        siteMessageEnabled
        siteMessages {
          messageLocation
          shortCopy
        }
      }
    }
  }
`

const PageNotFound = (props) => {
  const pageInformation = (
    <PageInformation
      title="Page Not Found - Travelers Sustainability Report"
      description="Travelers endorses and supports the definition of corporate social responsibility as set by the World Business Council for Sustainable Development."
    />
  )
  const { ecsSitewideMessages } = props?.data

  const sitewideMessages = getSitewideMessages(ecsSitewideMessages?.content)
  const headData = {
    pageInformation: pageInformation,
    htmlAttributes: { lang: "en-us" },
    canonicalUrl: "",
  }
  return (
    <BaseLayout head={headData} sitewideMessages={sitewideMessages}>
      <main>
        <div className="content-main">
          <div className="heading">Page Not Found</div>
          <div id="error-content">
            <div className="subheading">
              We are sorry. We can not find the page you requested.
            </div>
            The page you are looking for may have been removed from the site or
            the link you followed may no longer work. If you entered the URL
            directly, you might have entered something incorrectly. Try
            searching or
            <Link to="/"> click here to return to our home page.</Link>
          </div>
        </div>
      </main>
    </BaseLayout>
  )
}
export default PageNotFound

PageNotFound.propTypes = {
  data: shape({
    ecsSitewideMessages: object,
  }),
}
